import Head from 'next/head';
import Script from 'next/script';
import { LogoJsonLd, SiteLinksSearchBoxJsonLd } from 'next-seo';
import Conditional from 'components/common/Conditional';
import { CollectionDetails } from 'components/StaticBanner/index';
import { TBreadcrumbs } from 'utils/breadcrumbsUtils';
import { getBreadcrumbLabel } from 'utils/helper';
import { convertUidToUrl, getDomainFromUid, getValidUrl } from 'utils/urlUtils';
import { siteNameMappings } from 'const/index';

export const TrackingScripts = ({
  isDev,
  isPreview,
}: {
  isDev: boolean;
  isPreview: boolean;
}) => {
  const isNonProd = isDev || isPreview;
  const GTM_CONTAINER_ID = 'GTM-5LJWNW3';
  let GTM_AUTH = isNonProd
    ? 'psi3hURmBLey31qAhn7cPA'
    : 'ueaj9d1HgXEpkUp-zbbP0Q';
  let GTM_ENV = isNonProd ? 'env-27' : 'env-1';
  GTM_AUTH = `&gtm_auth=${GTM_AUTH}`;
  GTM_ENV = `&gtm_preview=${GTM_ENV}&gtm_cookies_win=x`;

  return (
    <>
      <Script id="gtm-data">{`var dataLayer = dataLayer || [];`}</Script>
      <Script id="gtm-content">
        {`
				var dataLayer_content = [];
				dataLayer.push( dataLayer_content );`}
      </Script>
      <Script id="gtm-script">
        {`
				(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
				new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
				j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
				'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '${GTM_AUTH || ''}${
          GTM_ENV || ''
        }';f.parentNode.insertBefore(j,f);
				})(window,document,'script','dataLayer','${GTM_CONTAINER_ID}');`}
      </Script>
    </>
  );
};

export const WebpageJsonLD = ({
  uid,
  lang,
  title,
  favicon = '',
  logo,
  description,
  datePublished,
  dateModified,
  hasSearchEnabled,
}: {
  uid: string;
  lang: string;
  title: string;
  logo: string;
  favicon: string;
  description: string;
  datePublished?: string;
  dateModified?: string;
  hasSearchEnabled?: boolean;
}) => {
  const contentPageUrl = convertUidToUrl({ uid, lang });
  const microbrandUrl = contentPageUrl
    ? getValidUrl(new URL(contentPageUrl).hostname)
    : '';
  let siteName = null;
  for (let [siteNameKey, siteNameValue] of siteNameMappings.entries()) {
    if (uid.includes(siteNameKey)) {
      siteName = siteNameValue;
      break;
    }
  }

  // NEXT-SEO doesn't have components for webpage/website. Migrate this once they release the same
  const baseSchema = [
    {
      '@context': 'https://schema.org',
      '@graph': [
        {
          '@type': 'WebSite',
          '@id': `${microbrandUrl}/#website`,
          url: `${microbrandUrl}`,
          name: `${siteName ?? title}`,
        },
        {
          '@type': 'ImageObject',
          '@id': `${contentPageUrl}#primaryimage`,
          url: `${favicon}`,
          width: 1727,
          height: 453,
        },
        {
          '@type': 'WebPage',
          '@id': `${contentPageUrl}#webpage`,
          url: `${contentPageUrl}`,
          inLanguage: `${lang}`,
          name: `${title}`,
          isPartOf: { '@id': `${microbrandUrl}/#website` },
          primaryImageOfPage: {
            '@id': `${contentPageUrl}#primaryimage`,
          },
          description,
          datePublished,
          dateModified,
        },
      ],
    },
  ];
  const domainName = getDomainFromUid(uid);
  // If no path then we assume it is the homepage. (interim solution)
  const isHomepage =
    // @ts-expect-error TS(2769): No overload matches this call.
    uid.split(domainName)?.filter((string) => string.length)?.length === 0;

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(baseSchema) }}
        />
      </Head>
      <Conditional if={isHomepage}>
        <LogoJsonLd logo={logo} url={microbrandUrl} />
      </Conditional>
      <Conditional if={isHomepage && hasSearchEnabled}>
        <SiteLinksSearchBoxJsonLd
          url={microbrandUrl}
          potentialActions={[
            {
              target: `${microbrandUrl}/?s`,
              queryInput: 'search_term_string',
            },
          ]}
        />
      </Conditional>
    </>
  );
};

export const MystiquePerfScript = ({
  serverRequestStartTimestamp,
}: {
  serverRequestStartTimestamp: string;
}) => (
  <Head>
    <script
      dangerouslySetInnerHTML={{
        __html: `
      var mystiquePerf = {
        serverTimestamp: ${serverRequestStartTimestamp},
        clientTimestamp: null,
        ttl: null,
        ttlUnits: 'ms',
        fired: false
      };
      if (!mystiquePerf.fired) {
        mystiquePerf.clientTimestamp = ${Math.floor(new Date().getTime())};
        mystiquePerf.ttl = mystiquePerf.clientTimestamp - mystiquePerf.serverTimestamp;
        mystiquePerf.fired = true;
      }
    `,
      }}
    />
  </Head>
);

export const CollectionAggregatedRatingScript = ({
  collectionDetails,
}: {
  collectionDetails: CollectionDetails | undefined;
}) => {
  if (!collectionDetails) return null;

  const {
    id,
    displayName: name,
    heroImageUrl,
    cardImageUrl,
    metaDescription: description,
    ratingsCount: ratingCount,
    averageRating: ratingValue,
    listingPrice: lowPrice,
    currency: priceCurrency,
  } = collectionDetails;
  const itemList = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name,
    image: [heroImageUrl || cardImageUrl],
    description,
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: ratingValue.toPrecision(2),
      bestRating: 5,
      worstRating: 1,
      ratingCount,
    },
    offers: {
      '@type': 'AggregateOffer',
      availability: 'https://schema.org/InStock',
      lowPrice,
      priceCurrency,
    },
  };
  return (
    <Head>
      <script
        key={id}
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(itemList) }}
      />
    </Head>
  );
};

export type VideoMetaInfo = {
  url: string;
  name: string;
  thumbnailUrl: string;
  duration: number;
  uploadDate: string;
};

export const VideoMetaScript = ({
  videoInfo,
}: {
  videoInfo: VideoMetaInfo | null;
}) => {
  if (!videoInfo) return null;

  const { name, thumbnailUrl, url, duration, uploadDate } = videoInfo;
  const formattedVideoDuration = `PT${duration}S`;
  const formattedUploadDate = new Date(uploadDate).toISOString();

  const itemList = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name,
    thumbnailUrl,
    uploadDate: formattedUploadDate,
    duration: formattedVideoDuration,
    contentUrl: url,
  };
  return (
    <Head>
      <script
        key={'videoScriptMetadata'}
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(itemList) }}
      />
    </Head>
  );
};

export const BreadcrumbsSchema = ({
  breadcrumbs,
  mbCity,
  showName,
}: {
  breadcrumbs: TBreadcrumbs;
  mbCity: string;
  showName: string;
}) => {
  const itemListElement = Object.values(breadcrumbs).map(
    (breadcrumb, index) => {
      const { level, label, url } = breadcrumb;
      return {
        '@type': 'ListItem',
        position: level,
        name: getBreadcrumbLabel({
          label,
          mbCity,
          showName,
        }),
        ...(index !== Object.values(breadcrumbs).length - 1 && { item: url }),
      };
    }
  );
  const breadcrumbList = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement,
  };

  return (
    <Head>
      <script
        key={'breadcrumbsSchema'}
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbList) }}
      />
    </Head>
  );
};
